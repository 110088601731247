<template>
  <div id="livraison-modifie-adresse">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <Button
          color="red"
          :text="$t('account.copyAddress')"
          @click="copyBillingAddress()"
        />
      </div>
      <div class="col-12 d-flex justify-content-center">
        <form @submit.stop.prevent="submit()">
          <div class="row mb-4">
            <div class="col-12 col-md-3">
              <span for="nom">{{ $t('user.fullname') }}</span>
            </div>
            <div class="col-12 col-md-3">
              <UserCourtesyField v-model="$v.address.courtesyTitle.$model" :rules="$v.address.courtesyTitle" />
            </div>
            <div class="col-6 col-md-3 mt-3 mt-md-0">
              <UserNameField v-model="$v.address.firstname.$model" :rules="$v.address.firstname" name="firstname" :placeholder="$t('user.firstname')" />
            </div>
            <div class="col-6 col-md-3 mt-3 mt-md-0">
              <UserNameField v-model="$v.address.lastname.$model" :rules="$v.address.lastname" name="lastname" :placeholder="$t('user.lastname')" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-3">
              <span for="address">{{ $t('user.street') }}</span>
            </div>
            <div class="col-12 col-md-9">
              <UserStreetField v-model="$v.address.street.$model" :rules="$v.address.street" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-3">
              <span for="ville">{{ $t('user.cityGroup') }}</span>
            </div>
            <div class="col-12 col-md-9 right-padding">
              <UserZipField v-model="$v.address.zip.$model" :rules="$v.address.zip" :city.sync="$v.address.city.$model" />
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-3">
              <span for="pais">{{ $t('user.city') }} *</span>
            </div>
            <div class="col-8 col-md-6">
              <UserCountryField v-model="$v.address.pays_id.$model" :rules="$v.address.pays_id" :pick="['ch']" />
              <span>* {{ $t('account.deliveryOnlyCH') }}</span>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-12 col-md-3">
              <span for="address">{{ $t('user.phone') }}</span>
            </div>
            <div class="col-8 col-md-6">
              <UserPhoneField v-model="$v.address.phone.$model" :rules="$v.address.phone" />
            </div>
          </div>
          <div class="row submit-button">
            <div class="col-12 offset-md-3 col-md-2">
              <Button
                color="yellow"
                :text="$t('user.submit')"
                :disabled="$v.$invalid || loading"
                @click="submit()"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue'
import UserCourtesyField from '@/components/User/UserCourtesyField'
import UserNameField from '@/components/User/UserNameField'
import UserCountryField from '@/components/User/UserCountryField'
import UserZipField from '@/components/User/UserZipField'
import UserStreetField from '@/components/User/UserStreetField'
import UserPhoneField from '@/components/User/UserPhoneField'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'LivraisonModifieAdresse',
  components: {
    Button,
    UserCourtesyField,
    UserNameField,
    UserCountryField,
    UserZipField,
    UserStreetField,
    UserPhoneField
  },
  data: () => ({
    loading: false,
    address: {}
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user']
    }
  },
  validations: {
    address: {
      courtesyTitle: { required },
      pays_id: { required },
      firstname: { required },
      lastname: { required },
      street: { required },
      zip: { required },
      city: { required },
      phone: { required }
    }
  },
  mounted() {
    this.address = {
      ...(this.user.deliveryCoordinate || {})
    }
  },
  methods: {
    copyBillingAddress() {
      this.skipWatchZip = true
      this.address = {
        courtesyTitle: this.user.courtesyTitle,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        ...this.user.coordinate
      }
    },

    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      return this.$store.dispatch('auth/saveDeliveryAddress', { address: this.address })
        .then(res => this.$success(res, { message: 'address.saved' }))
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#livraison-modifie-adresse {
    padding-top: 15px;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
      padding-top: 40px;
    }

    button {
      margin: 0px !important;
    }

    .bold {
        font-weight: 900;
    }

    form {
        padding: 40px 20px;
        margin: 0px 5px;
        text-align: left;

        @include media-breakpoint-up(lg) {
            padding: 40px 100px;
        }

        span {
            font-size: 16px;
            font-weight: 700;
            color: #fff;
        }

        input {
            width: 100%;
            height: 36px;
            box-sizing: border-box;
            border: none;
            box-shadow: inset 1px 1px 3px #0000009a;
            padding: 10px;
            font-size: 15px;
        }

        .right-padding {
            @include media-breakpoint-down(md) {
            padding-right: 5px;
            }
        }
        .left-padding {
            @include media-breakpoint-down(md) {
                padding-left: 5px;
            }
        }

        .pass-input input {
            width: 100%;
            box-sizing: border-box;
        }
    }
}

</style>
