<template>
  <div id="livraison-domicile">
    <div class="row">
      <div v-if="hubsHome.length && !hubs.length">
        {{ $t('cartDelivery.hubNoHome', { zip: deliveryZip }) }}
        <ul>
          <li v-for="zip in availableZips" :key="zip.slug"><strong>{{ zip.npa }}</strong> {{ zip.name }}</li>
        </ul>
      </div>
      <div
        v-for="h in hubs"
        :key="h.id"
        class="col-12 col-md-5 col-lg-3 point"
        :class="{ selected: hub && h.id === hub.id }"
      >
        <div class="point-content">
          <div class="name">
            {{ h.name }}
          </div>
          <div class="info">
            {{ h.coordinate.street }}
            <br />{{ h.coordinate.npa }} {{ h.coordinate.city }}
            <br />{{ h.coordinate.phone }}
          </div>
          <div class="frais">
            {{ $t('cartDelivery.fees') }} {{ getFee(h) | price }}
          </div>
          <Button
            color="red"
            text="Choisir"
            @click="select(h)"
          />
        </div>
      </div>
      <div v-if="selectedDeliveryDate" class="col-12 col-md-6 offset-lg-1 col-lg-7 pt-5 pt-md-0">
        <span class="bold">{{ $t('cartDelivery.datetime') }}</span><br>
        <div class="row pt-3 date-selected">
          <div class="col-6 col-lg-3 day">
            {{ selectedDeliveryDate.date | date('LL') }}
          </div>
          <div class="col-6">
            {{ selectedDeliveryDate.slice }}
          </div>
        </div>
        <div class="row pt-3">
          <div v-marked="$t('cartDelivery.hourInfo')" class="col-12 col-lg-6 small-text"></div>
        </div>
        <div class="row pt-3">
          <form>
            <div class="col-12 col-lg-10 form-group">
              <label
                for="MessageLivreur"
                class="bold pb-4 mt-3 mt-lg-5"
              >{{ $t('cartDelivery.message') }}</label>
              <textarea
                id="MessageLivreur"
                v-model="message"
                class="form-control"
                rows="8"
                :placeholder="$t('cartDelivery.messageSample')"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue'
import lodash from 'lodash'

export default {
  name: 'LivraisonDomicile',
  components: {
    Button
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false,
    selectedDeliveryDate: null,
    message: null,
    hub: null
  }),
  computed: {
    deliveryZip() {
      return this.$store.getters['auth/user'].deliveryCoordinate?.zip
    },

    hubsHome() {
      return this.$store.getters['partner/hubsByMode']('home')
    },

    hubs() {
      return this.hubsHome.filter(hub => hub.deliverZipCode(this.deliveryZip))
    },

    availableZips() {
      return Object
        .values(this.hubsHome.reduce((acc, hub) => {
          const npa = hub.getDeliveryMode('home')?.npa || []
          npa.forEach(o => (acc[o.slug] = o))
          return acc
        }, {}))
        .sort((a, b) => a.npa >= b.npa ? 1 : -1)
    },

    cart() {
      return this.$store.getters['cart/cart']
    },

    modalDone() {
      return this.$store.getters['modal/doneSwitch']
    }
  },
  watch: {
    modalDone() {
      this.setHubDelivery(this.$store.getters['modal/doneParams'].hubId)
    },
    message: 'setMessage'
  },
  mounted() {
    this.loading = true
    return Promise
      .all([
        this.$store.dispatch('partner/hubs', { id: this.partnerId }),
        this.$store.dispatch('cart/get', { partnerId: this.partnerId })
      ])
      .then(data => {
        if (data[1]?.delivery) {
          this.message = data[1].delivery.note
          return this.setHubDelivery(data[1].delivery.salepoint)
        }
      })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },
  methods: {
    getFee(hub) {
      return hub.getDeliveryMode('pickup')?.fees
    },
    select(hub) {
      this.$store.commit('modal/show', {
        component: 'HubDeliveryPopup',
        params: {
          partnerId: this.partnerId,
          type: 'home',
          hub: hub
        }
      })
    },
    setHubDelivery(hubId) {
      if (!hubId) {
        return
      }
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('hub/get', { id: hubId }),
          this.$store.dispatch('hub/getOpenings', { id: hubId })
        ])
        .then(data => {
          this.hub = data[0]
          const slice = this.cart.getDeliverySlicedHours(data[1])
          this.selectedDeliveryDate = {
            date: this.cart.delivery.date,
            slice: slice?.text
          }
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    setMessage: lodash.debounce(function() {
      this.loading = true
      return this.$store.dispatch('cart/setDeliveryNote', { note: this.message })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }, 500)
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#livraison-domicile {
    padding-top: 40px;
    padding-bottom: 20px;
    font-weight: 300;
    border-top: 1px solid white;

    .point {
        font-weight: 300;

        .point-content {
          border: 1px dotted rgba(255,255,255,1);
          padding: 20px !important;
        }

        .name {
            font-weight: 900;
        }
        .frais {
            padding-top: 20px;
        }
    }
    .point.selected .point-content{
      background-color: rgba(255,255,255,0.5);
    }
    .bold {
        font-weight: 900;
    }
    .date-selected {
        font-weight: 900;
    }
    .small-text {
        font-size: 13px;
    }
}

</style>
