<template>
  <div id="livraison-content">
    <Totals :partner-id="partnerId" class="mt-5" />
    <div class="row m-0">
      <div class="col-12 p-0">
        <div class="livraison-methode">
          <div class="form-check">
            <div>
              <input
                id="radioPointCollecte"
                v-model="deliveryMethod"
                class="form-check-input"
                type="radio"
                value="point"
                name="radioLivraison"
                :disabled="loading"
              >
            </div>
            <div>
              <label
                class="form-check-label"
                for="radioPointCollecte"
              >
                {{ $t('cartDelivery.pickup') }}
              </label>
            </div>
          </div>
          <div v-if="deliveryMethod=='point'">
            <LivraisonPointCollecte v-if="!loading" :partner-id="partnerId" />
          </div>
        </div>
        <div v-if="hubsHome.length" class="livraison-methode">
          <div class="form-check">
            <div>
              <input
                id="radioDomicile"
                v-model="deliveryMethod"
                class="form-check-input"
                type="radio"
                value="domicile"
                name="radioLivraison"
                :disabled="loading || deliveryHomeDisabled"
              >
            </div>
            <div>
              <label
                class="form-check-label"
                for="radioDomicile"
              >
                {{ $t('cartDelivery.home') }}
              </label>
            </div>
          </div>
          <div class="adresse row">
            <div class="col-12 col-lg-6">
              <template v-if="address">
                {{ address.street }} {{ address.zip }} {{ address.city }}
              </template>
              <div v-if="noCityMinimum">{{ $t('cartDelivery.noCityMinimum', { amount: $options.filters.price(cart.minimumPrice) }) }}</div>
              <div v-if="hasFrozenArticles">{{ $t('cartDelivery.frozenArticles') }}</div>
            </div>
            <div
              class="col-12 col-lg-6 pt-4 pt-lg-0 modifier-adresse"
              :class="{ active: modifierActive }"
            >
              <span>{{ $t('cartDelivery.updateAddress') }}</span>
              <img
                src="@/assets/modifier-addresse-arrow.svg"
                @click="modifierActive = !modifierActive"
              >
            </div>
          </div>
          <div
            class="modifie-adresse-content row"
            :class="{ active: modifierActive }"
          >
            <div class="col-12 col-lg-10">
              <LivraisonModifieAdresse />
            </div>
          </div>
          <div v-if="deliveryMethod=='domicile' && !deliveryHomeDisabled">
            <LivraisonDomicile v-if="!loading" :partner-id="partnerId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Totals from '@/components/CartCheckout/Totals.vue'
import LivraisonPointCollecte from '@/components/CartCheckout/LivraisonPointCollecte.vue'
import LivraisonModifieAdresse from '@/components/CartCheckout/LivraisonModifieAdresse.vue'
import LivraisonDomicile from '@/components/CartCheckout/LivraisonDomicile.vue'
export default {
  name: 'LivraisonContent',
  components: {
    Totals,
    LivraisonPointCollecte,
    LivraisonModifieAdresse,
    LivraisonDomicile
  },
  props: {
    partnerId: { type: String }
  },

  data: () => ({
    loading: false,
    modifierActive: false,
    deliveryMethod: null,
    deliveryHomeDisabled: false,
    noCityMinimum: false,
    hasFrozenArticles: false
  }),

  computed: {
    cart() {
      return this.$store.getters['cart/cart']
    },
    address() {
      return this.$store.getters['auth/user'].deliveryCoordinate
    },
    hubsHome() {
      return this.$store.getters['partner/hubsByMode']('home')
    }
  },

  watch: {
    deliveryMethod(newValue, oldValue) {
      if (oldValue !== null) {
        return this.resetDelivery()
      }
    }
  },

  mounted() {
    this.loading = true

    const prefs = this.$store.getters['user/preferences']

    return Promise
      .all([
        this.$store.dispatch('cart/get', { partnerId: this.partnerId }),
        this.$store.dispatch('partner/hubs', { id: this.partnerId })
      ])
      .then(([cart]) => {
        if (cart.delivery?.mode === 'pickup') {
          this.deliveryMethod = 'point'
        } else if (cart.delivery?.mode === 'home') {
          this.deliveryMethod = 'domicile'
        } else if (prefs.hubId) {
          // nothing selected, but a preferred hub exists
          this.deliveryMethod = 'point'
        }
        return this.checkIfDeliveryHomeIsAvailable(cart)
      })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },

  methods: {
    resetDelivery() {
      this.loading = true
      return this.$store.dispatch('cart/resetDelivery', {})
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    async checkIfDeliveryHomeIsAvailable(cart) {
      // frozen articles can't be delivered at home
      this.hasFrozenArticles = cart.hasPackagingPacketOffer('frozen')
      this.noCityMinimum = cart.getTotalPriceStuff() < cart.minimumPrice

      // if cart amount is lower than standard city amount, home delivery must be disabled
      this.deliveryHomeDisabled = this.noCityMinimum || this.hasFrozenArticles

      // if cart was in home delivery, switch back to hub pickup
      if (this.deliveryHomeDisabled && this.deliveryMethod === 'domicile') {
        await this.resetDelivery()
        this.deliveryMethod = 'point'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
#totals {
  margin-top: 45px !important;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

#livraison-content {
    text-align: left;
    font-weight: 700;
    padding: 0;

    @include media-breakpoint-up(md) {
        margin-left: 5%;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 10%;
    }
    .livraison-methode {
        background-color: var(--yellow-color);
        padding: 20px 15px;
        margin-top: 20px;

        @include media-breakpoint-up(md) {
          padding: 30px;
        }

        .form-check {
            display: flex;
            align-items: center;
            padding-left: 0;

            .form-check-input {
                margin: 0 20px 0 0;
                width: 1.4em;
                height: 1.4em;
                @include media-breakpoint-up(lg) {
                    width: 1em;
                    height: 1em;
                }
            }
        }
        .adresse {
            font-weight: 300;
            padding-left: 35px;
            padding-top: 10px;

            .modifier-adresse {
                padding-top: 30px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;

                span {
                  padding-right: 10px;
                }

                img {
                    padding-left: 20px;
                    cursor: pointer;
                }
            }
        }
        .modifie-adresse-content {
            border-top: 1px solid white;
            display: none;
        }
    }
}
#livraison-content .modifier-adresse.active {
    span {
      font-weight: 700;
    }
    img {
        transform: rotate(90deg);
        padding: 0 !important;
    }
}
#livraison-content .modifie-adresse-content:not(.active) {
    display: none;
}
#livraison-content .modifie-adresse-content.active {
    display: flex;
}
</style>
