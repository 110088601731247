<template>
  <div class="livraison-container">
    <CartCheckoutHeader
      :section2="true"
      :title="$t('delivery.title')"
    />
    <LivraisonContent :partner-id="partnerId" />
    <div class="row d-flex buttons flex-md-row-reverse">
      <div class="col-md-2" />
      <div class="col-12 col-md-4 d-flex justify-content-lg-end">
        <router-link :to="{ name: 'payment' }">
          <button type="button" :disabled="nextDisabled">
            {{ $t('delivery.continue') }}
          </button>
        </router-link>
      </div>
      <div class="col-12 d-flex justify-content-start col-md-6">
        <router-link :to="{ name: 'shops' }">
          <button type="button">
            {{ $t('delivery.buy') }}
          </button>
        </router-link>
      </div>
    </div>
    <FooterSpacer />
  </div>
</template>

<script>
import CartCheckoutHeader from '@/components/CartCheckout/CartCheckoutHeader.vue'
import LivraisonContent from '@/components/CartCheckout/LivraisonContent.vue'
import FooterSpacer from '@/components/Layout/FooterSpacer'

export default {
  name: 'ViewLivraison',
  components: {
    CartCheckoutHeader,
    LivraisonContent,
    FooterSpacer
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    nextDisabled() {
      const delivery = this.$store.getters['cart/cart']?.delivery
      if (delivery?.date) {
        return false
      }
      return true
    }
  },
  mounted() {
    this.loading = true
    return this.$store.dispatch('cart/get', { partnerId: this.partnerId })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';

.livraison-container {
  .buttons {
      padding: 30px 0;
      margin: 0px !important;
      @include media-breakpoint-up(md) {
          margin-left: 5% !important;
      }

      @include media-breakpoint-up(lg) {
          margin-left: 10% !important;
      }
  }
  button {
      background-color: var(--red-color);
      border: none;
      padding: 10px 20px;
      border-radius: 10px !important;
      color: white;
      font-size: 18px;
      font-weight: 700;
      box-shadow: 1px 1px 5px #00000087;
      margin-top: 20px !important;

      &[disabled="disabled"] {
        background-color: #ccc;
      }
  }
}
</style>
