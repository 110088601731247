<template>
  <div id="livraison-point-collecte">
    <div class="row">
      <div
        v-for="h in hubs"
        :key="h.id"
        class="col-12 col-md-6 col-lg-3 pt-5 pt-md-0 point"
        :class="{ selected: isHubSelected(h) }"
      >
        <div class="name">
          {{ h.name }} {{ h.deliveryModes }}
        </div>
        <div class="info">
          {{ h.getStreet() }}<br>
          {{ h.getZipCity() }}<br>
          {{ h.getPhone() }}
        </div>
        <div class="frais">
          {{ $t('cartDelivery.fees') }} {{ getPickupFee(h) | price }}
        </div>
        <Button
          color="red"
          :text="$t('cartDelivery.choose')"
          @click="selectDelivery(h)"
        />
      </div>
      <div
        v-if="privateCount && privateCount.count"
        class="col-12 col-md-6 col-lg-4 point pt-5 pt-md-0 private"
      >
        <p>{{ $t('cartDelivery.privateHub') }}</p>
        <input v-model="code" type="text" name="code" :placeholder="$t('cartDelivery.privateHubCode')" />
        <Button color="red" :text="$t('cartDelivery.choose')" @click="searchPrivateHub()" />
      </div>
      <div v-if="cart && cart.hasPackagingPacketOffer('frozen') && !hubs.length">
        {{ $t('cartDelivery.emptyBecauseFrozenArticles') }}
      </div>
    </div>
    <div v-if="hub" class="row details">
      <div class="col-12 col-md-2 d-flex justify-content-md-center">
        <img src="@/assets/point-collecte-icon.svg">
      </div>
      <div class="col-12 col-md-10 pt-3 pt-md-0">
        {{ $t('cartDelivery.deliverAt') }} <span class="bold">{{ hub.name }}</span><br>
        <div
          v-if="delivery"
          v-marked="$t('cartDelivery.deliverHour', { date: delivery.date, hours: getHours(delivery.date) })"
          class="pt-3"
        >
        </div>
      </div>
    </div>
    <div class="row details">
      <div class="col-12 col-md-2 d-flex justify-content-md-center">
        <img src="@/assets/hand.svg">
      </div>
      <div class="col-12 col-md-9 col-lg-6 pt-3 pt-md-0">
        {{ $t('cartDelivery.hubInfo') }}
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI/Button.vue'

export default {
  name: 'LivraisonPointCollecte',
  components: {
    Button
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    loading: false,
    hub: null,
    hubTimetable: null,
    code: null,
    privateCount: null
  }),
  computed: {
    shops() {
      return this.$store.getters['cart/shops']
    },
    cart() {
      return this.$store.getters['cart/cart']
    },
    hubs() {
      let hubs = this.$store.getters['hub/privateHubs'].concat(this.$store.getters['partner/hubsByMode']('pickup'))
      // specific case where we have only one SHOP in cart
      if (this.shops.length === 1) {
        const shop = this.shops[0]
        // If we have one SHOP, J+0 may be available, but it depends on current time.
        // Depending on that, we will show the corresponding HUB.
        const table = this.$store.getters['shop/timetable'](shop.id)
        if (table && !this.cart.minimumStandardReached()) {
          hubs = hubs.filter(hub => hub.profile.id === shop.profile.id)
        }
        // Moreover, the city/shop minimum amount can be different if shop is alone.
        // In that case, we will hide HUBs that need the standard minimum amount
        if (this.cart.getMinimumPriceSoloForShop(shop.id) && !this.cart.minimumStandardReached()) {
          hubs = hubs.filter(hub => hub.profile.id === shop.profile.id)
        }
      }
      // And finally, if we have frozen stuff, we must show only hubs owned by
      // the same profile as shop
      this.shops.forEach(shop => {
        if (shop.hasPackagingPacketOffer('frozen')) {
          hubs = hubs.filter(hub => hub.profile.id === shop.profile.id)
        }
      })
      return hubs
    },
    delivery() {
      return this.$store.getters['cart/cart']?.delivery
    },
    modalDone() {
      return this.$store.getters['modal/doneSwitch']
    }
  },
  watch: {
    modalDone() {
      this.setHubDelivery(this.$store.getters['modal/doneParams'].hubId)
    }
  },
  mounted() {
    this.loading = true

    const prefs = this.$store.getters['user/preferences']

    return Promise
      .all([
        // force reload because of price calculation, which is different at start
        // without cart and here when cart exists
        this.$store.dispatch('partner/hubs', { id: this.partnerId }),
        this.$store.dispatch('cart/get', { id: this.partnerId }),
        this.$store.dispatch('hub/countPrivate', { partnerId: this.partnerId })
      ])
      .then(async data => {
        this.privateCount = data[2]

        // get favourite configured private hubs
        const favouritePrivateHubs = this.$store.getters['auth/privateHubs'](this.partnerId)
        await Promise
          .all(favouritePrivateHubs.map(row => this.$store.dispatch('hub/searchPrivate', {
            partnerId: this.partnerId,
            code: row.code
          })))
          .catch(err => {
            // skip error, since user can re-input code to accept new hub
            // eslint-disable-next-line
            console.log(err)
          })

        if (data[1]?.delivery?.salepoint) {
          return this.setHubDelivery(data[1].delivery.salepoint)
        }
        if (prefs.hubId && this.hubs.find(h => h.id === prefs.hubId)) {
          return this.setHubDelivery(prefs.hubId).then(() => {
            return this.selectDelivery(this.hub)
          })
        }
      })
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },
  methods: {
    getPickupFee(hub) {
      return hub.getDeliveryMode('pickup')?.fees
    },

    getHours(date) {
      if (!this.hub || !this.hubTimetable) {
        return
      }
      return this.hub.getOpeningHours(date, this.hubTimetable)
    },

    selectDelivery(hub) {
      this.$store.commit('modal/show', {
        component: 'HubDeliveryPopup',
        params: {
          partnerId: this.partnerId,
          type: 'pickup',
          hub: hub
        }
      })
    },

    setHubDelivery(hubId) {
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('hub/get', { id: hubId }),
          this.$store.dispatch('hub/getOpenings', { id: hubId })
        ])
        .then(data => {
          this.hub = data[0]
          this.hubTimetable = data[1]
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    isHubSelected(hub) {
      return this.hub?.id === hub.id
    },

    searchPrivateHub() {
      this.loading = true
      return this.$store.dispatch('hub/searchPrivate', { partnerId: this.partnerId, code: this.code })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#livraison-point-collecte {
    font-weight: 300;
    padding: 0px 27px 80px 27px;

    .point {
        font-weight: 300;
        padding: 20px !important;
        margin: 20px;
        border: 1px dotted rgba(255,255,255,1);

        .name {
            font-weight: 900;
        }
        .frais {
            padding-top: 20px;
        }
    }
    .point.selected {
      background-color: rgba(255,255,255,0.5);
    }
    .bold {
        font-weight: 900;
    }
    .details {
        display: flex;
        align-items: center;
        padding-top: 20px;

        @include media-breakpoint-up(md) {
          padding-top: 50px;
        }

        img {
            max-width: 80px;
            max-height: 80px;
            margin-right: 30px;
        }
    }
}
</style>
